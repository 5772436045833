// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-quote-page-template-jsx": () => import("A:\\dev\\Ohar.name\\src\\templates\\QuotePageTemplate.jsx" /* webpackChunkName: "component---src-templates-quote-page-template-jsx" */),
  "component---src-templates-dw-pc-class-template-jsx": () => import("A:\\dev\\Ohar.name\\src\\templates\\DwPcClassTemplate.jsx" /* webpackChunkName: "component---src-templates-dw-pc-class-template-jsx" */),
  "component---src-pages-404-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-articles-funny-js-button-title-index-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\funny-js\\button-title\\index.jsx" /* webpackChunkName: "component---src-pages-articles-funny-js-button-title-index-jsx" */),
  "component---src-pages-articles-funny-js-damned-jsx-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\funny-js\\damned-jsx.jsx" /* webpackChunkName: "component---src-pages-articles-funny-js-damned-jsx-jsx" */),
  "component---src-pages-articles-funny-js-index-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\funny-js\\index.jsx" /* webpackChunkName: "component---src-pages-articles-funny-js-index-jsx" */),
  "component---src-pages-articles-funny-js-instanceof-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\funny-js\\instanceof.jsx" /* webpackChunkName: "component---src-pages-articles-funny-js-instanceof-jsx" */),
  "component---src-pages-articles-funny-js-negative-indexes-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\funny-js\\negative-indexes.jsx" /* webpackChunkName: "component---src-pages-articles-funny-js-negative-indexes-jsx" */),
  "component---src-pages-articles-funny-js-new-date-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\funny-js\\new-date.jsx" /* webpackChunkName: "component---src-pages-articles-funny-js-new-date-jsx" */),
  "component---src-pages-articles-funny-js-semicolon-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\funny-js\\semicolon.jsx" /* webpackChunkName: "component---src-pages-articles-funny-js-semicolon-jsx" */),
  "component---src-pages-articles-how-does-a-visually-impaired-computer-programmer-do-programming-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\how-does-a-visually-impaired-computer-programmer-do-programming.jsx" /* webpackChunkName: "component---src-pages-articles-how-does-a-visually-impaired-computer-programmer-do-programming-jsx" */),
  "component---src-pages-articles-index-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\index.jsx" /* webpackChunkName: "component---src-pages-articles-index-jsx" */),
  "component---src-pages-articles-rules-index-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\rules\\index.jsx" /* webpackChunkName: "component---src-pages-articles-rules-index-jsx" */),
  "component---src-pages-articles-rules-rules-html-bw-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\rules\\rules-html-bw.jsx" /* webpackChunkName: "component---src-pages-articles-rules-rules-html-bw-jsx" */),
  "component---src-pages-articles-rules-rules-html-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\rules\\rules-html.jsx" /* webpackChunkName: "component---src-pages-articles-rules-rules-html-jsx" */),
  "component---src-pages-articles-rules-rules-raw-text-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\rules\\RulesRawText.jsx" /* webpackChunkName: "component---src-pages-articles-rules-rules-raw-text-jsx" */),
  "component---src-pages-articles-smotrya-na-kod-s-zakrytymi-glazami-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\articles\\smotrya-na-kod-s-zakrytymi-glazami.jsx" /* webpackChunkName: "component---src-pages-articles-smotrya-na-kod-s-zakrytymi-glazami-jsx" */),
  "component---src-pages-dw-common-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\dw\\common.jsx" /* webpackChunkName: "component---src-pages-dw-common-jsx" */),
  "component---src-pages-dw-index-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\dw\\index.jsx" /* webpackChunkName: "component---src-pages-dw-index-jsx" */),
  "component---src-pages-index-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-quotes-jsx": () => import("A:\\dev\\Ohar.name\\src\\pages\\quotes.jsx" /* webpackChunkName: "component---src-pages-quotes-jsx" */)
}

